<template>
  <div>
    <Transition name="fade" mode="out-in">
      <div v-if="!isSubmitted">
        <div v-if="title" :class="classList">{{ title }}</div>
        <div class="flex flex-col justify-center">
          <form
            name="NewsletterSubscription"
            novalidate="true"
            :class="formClass"
            @submit.prevent="submitForm()"
          >
            <FormInput
              v-model="state.email"
              classField="!mb-2"
              :placeholder="$t('newsletterSubscribe.email.placeholder')"
              :isLabelVisible="false"
              label="Email"
              type="email"
              :style="style as InputStyles"
              :isError="v$.email.$error"
              :errorMessage="$t('newsletterSubscribe.email.errorMessage')"
              :errorFixed="false"
              @change="v$.email.$touch"
            />
            <FormCheckbox
              v-model="state.agree"
              name="agree"
              classLabel="!text-[15px] opacity-70"
              :label="$t('newsletterSubscribe.agree.label')"
              :style="style as InputStyles"
              :isError="v$.agree.$error"
              :errorMessage="$t('newsletterSubscribe.agree.errorMessage')"
              @change="v$.agree.$touch"
            />
            <Button
              class="mt-7"
              :label="$t('newsletterSubscribe.signUp')"
              color="orange"
              type="submit"
            />
          </form>
        </div>
      </div>
      <div v-else>
        <div class="mt-6" :class="classList">{{ $t('newsletterSubscribe.thankYou.title') }}</div>
        <!--
        <p class="text-body-15-regular">{{ $t('newsletterSubscribe.thankYou.body') }}</p>
        -->
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'
import { InputStyles } from './FormInput.vue'

interface NewsletterSubscribeProps {
  title?: string
  style?: InputStyles
  formClass?: string
}

withDefaults(defineProps<NewsletterSubscribeProps>(), {
  style: 'gray'
})

const isSubmitted = ref(false)

const classList = ref(
  'mb-7 text-heading-18-medium font-medium md:text-[15px] lg:text-body-20-regular'
)

const state = reactive({
  email: '',
  agree: false,
  listId: 27
})

const rules = {
  email: { required, email },
  agree: { sameAs: sameAs(true) }
}

const v$ = useVuelidate(rules, state)

async function submitForm() {
  const isFormInvalid = await v$.value.$validate()

  if (!isFormInvalid) {
    return
  }

  const { data, error } = await useFetch('/.netlify/functions/brevo', {
    headers: { 'Content-type': 'application/json' },
    method: 'POST',
    body: toRaw(state)
  })

  if (!data.value) {
    // If existing show ThankYou page.
    if (error?.value!.statusCode === 400) {
      isSubmitted.value = true
    } else {
      // eslint-disable-next-line
      console.error(error.value)
      throw createError({
        statusCode: error?.value!.statusCode,
        message: error?.value!.message,
        fatal: true
      })
    }
  } else {
    isSubmitted.value = true
  }
}
</script>
